require('./bootstrap');
require('./custom');
require('./new_cart');
require('./register');
require('./menu-mobile');
require('./components/widget-fretegratis');
require('./blog');
require('./helpers');
require('./autocomplete');
require('./reseller');
