if (document.getElementById("reseller")) {
    var app = window.app || {};

    // Buscar estados baseados no país selecionado
    app.searchEstados = async function(pais) {
        try {
            console.log(pais);
            const res = await window.axios.get(APP_URL + "/encontre-um-representante/get-estados/" + pais);
            console.log(res.data);
            const estados = res.data;
            app.constructSelectEstados(estados);
        } catch (e) {
            console.error(e);
        }
    }

    // Buscar cidades baseadas no estado e país selecionados
    app.searchCidades = async function(estado, pais) {
        try {
            const res = await window.axios.get(APP_URL + "/encontre-um-representante/get-cidades/" + pais + "/" + estado);
            const cidades = res.data;
            app.constructSelectCidades(cidades);
        } catch (e) {
            console.error(e);
        }
    }

    // Preencher dropdown de estados
    app.constructSelectEstados = function(estados) {
        var input = '<option value="">Selecione seu estado</option>',
            wrapper = $('#estado');

        estados.forEach(function(item) {
            input += '<option value="' + item.estado + '">' + item.estado + '</option>';
        });

        wrapper.html(input);
        $('#cidade').html('<option></option>'); // Resetar cidades
    }

    // Preencher dropdown de cidades
    app.constructSelectCidades = function(cidades) {
        var input = '<option value="0">Selecione a Cidade</option>',
            wrapper = $('#cidade');

        cidades.forEach(function(item) {
            input += '<option value="' + item.cidade + '">' + item.cidade + '</option>';
        });

        wrapper.html(input);
    }

    // Exibir representantes baseados no país, estado e cidade
    app.showRepresentantes = function(pais, estado = null, cidade = null) {
        console.log(pais, estado, cidade);
        $('.representantes__representante--outros').removeClass('d-none');

        if (!estado) {
            $('.representantes__representante__item').hide(200);
            $('.representantes__representante__item[data-pais="' + pais + '"]').delay(250).show(200);
        } else if (!cidade) {
            $('.representantes__representante__item[data-pais="' + pais + '"]').hide(100);
            $('.representantes__representante__item[data-pais="' + pais + '"][data-estado="' + estado + '"]').delay(150).show(200);
        } else {
            $('.representantes__representante__item[data-pais="' + pais + '"][data-estado="' + estado + '"]').hide(100);
            $('.representantes__representante__item[data-pais="' + pais + '"][data-estado="' + estado + '"][data-cidade="' + cidade + '"]').delay(150).show(200);
        }
    }

    // Evento de mudança no país
    $('#pais').change(function() {
        var pais = $(this).val();
        $('#estado').html('<option>Carregando...</option>');
        $('#cidade').html('<option></option>');
        $('.cidade-input').addClass('d-none');

        app.searchEstados(pais);
        app.showRepresentantes(pais);
    });

    // Evento de mudança no estado
    $('#estado').change(function() {
        var estado = $(this).val();
        var pais = $('#pais').val();

        if (estado) {
            app.searchCidades(estado, pais);
            app.showRepresentantes(pais, estado);
            $('.cidade-input').removeClass('d-none');
        }
    });

    // Evento de mudança na cidade
    $('#cidade').change(function() {
        var cidade = $(this).val(),
            estado = $('#estado').val(),
            pais = $('#pais').val();

        app.showRepresentantes(pais, estado, cidade);
    });

    // Inicializar se houver um estado pré-selecionado
    if ($('#estado option:selected').length) {
        var estado = $('#estado').val();
        var pais = $('#pais').val();
        app.searchCidades(estado, pais);
        $('.cidade-input').removeClass('d-none');
    }
}
